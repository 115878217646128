

.index {
    width: 100%;
    height: 100vh;

    .index_top {
        height: 50px;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.1500);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px 0 20px;

        .index_top_left {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 500;
            color: #1E2226;
        }
        .index_top_right {
            display: flex;
            flex-direction: row;
            align-items: center;

            .back {
                width: 128px;
                height: 30px;
                background-color: #F23B2F;
                margin-right: 30px;
                border-radius: 5px;
                text-align: center;
                line-height: 30px;
                color: #FFFFFF;
                font-size: 14px;
                cursor: pointer;
            }

            .index_top_right_img {
                width: 32px;
                height: 32px;
                opacity: 1;
                border: 1px solid #E6E7EB;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .index_top_right_text {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 500;
                color: #696970;
                margin-left: 8px;
                margin-right: 22px;
            }
        }
    }
    .index_bottom {
        width: 100%;
        height: calc(100% - 50px);
    }
}
